import { env } from "../helpers/env";

export const APIRoutes = { 
    BASE:  env === 'PROD' ? 'https://www.spice-global.org/api' : 'http://localhost:5000/api',
    HospitalsApply: '/hospital-applications',
    VerifyCaptcha: '/auth/verify-captcha',
    ContactUs: '/contact/',
    ExpertsApply: '/experts-applications',
    TrainersApply: '/trainers-applications',
    Tweets: '/auth/tweets'
};