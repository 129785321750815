import { AppRoutes } from "../../consts/routes/app-routes";
import "./about-spice.css";

const WhereWeWorkComponent = () => {
    return (
        <>
            <div class="content-about">
                <h4>Where We Work</h4>
                <p class="intro">
                SPICE has dedicated partners in Africa, Europe, South America and South-East Asia through the <a href="https://www.global-pps.com/drive-ams/" target="_blank">drive-AMS project</a>. In 2024, Stichting Reinier Post acknowledged the importance of providing ongoing expert support in these contexts. If you have expertise in any of these regions and want to learn more about becoming an expert, please read more and <a href={AppRoutes.ExpertApply}>apply here</a>.</p>

                <div>
                    {/* <h1>
                        Projects
                    </h1> */}
                    <p>
                    AMS Masterclasses have taken place in the following regions, and missions are planned to take place from 2025:
                    </p>
                    <h4 style={{marginBottom: "-7.5px"}}>drive-AMS Europe</h4>
                    <p>
                        drive-AMS project, co-funded by the EU4Health Programme, is aimed at building capacity for delivery of tailor-made contextualized drive-AMS training and expert support in national languages in 4 European countries: Greece, Lithuania, Portugal and Romania, as well as proof of concept of the effectiveness of the drive-AMS approach and methodology of measure-analyze-improve-re-measure.    
                    </p>
                    <h4 style={{marginBottom: "-7.5px"}}>AMS masterclass Cyprus</h4>
                    <p>
                    Follwing up on the drive-AMS success, the program is currently expanded to other European countries, including delivering the course in Cyprus in 2025, through the EU-JAMRAI partnership.                        </p>
                    <h4 style={{marginBottom: "-7.5px"}}>drive-AMS Africa</h4>
                    <p>
                    Co-funded by ICARS, this partnership project with East-African universities and hopsitals, enables buidling capacity of trainers and experts in the region. Since 2023, program has been delivered to Tanzania, Rwanda, Kenya, Zimbabwe, Uganda and Somalia, with first missions to be dispatched in 2024.
                    </p>
                    <h4 style={{marginBottom: "-7.5px"}}>drive-AMS Indonesia</h4>
                    <p>
                    Supported by the Dutch Institute for Public Health (RIVM), and under the Memorandum of Understanding between the health authorities of the Netherlands and Indonesia, this partnership project is aimed at building AMS capacity in Indonesian hospitals. Initial training is planned for 2024, and the first missions to follow in 2025.                        </p>
                    {/* <h4 style={{marginBottom: "-7.5px"}}>ESCMID-AMS Certificate programme</h4>
                    <p>The drive-AMS masterclass is the foundational course of the 2-year master-level certificate programme established and organized by ESCMID and ESGAP since 2022.
                        Read more about ESCMID-AMS certificate <a href="https://www.escmid.org/education/ams-certificate/"></a>here.</p>
                    <h4>SPICE registry and missions</h4>     
                    <p>In 2024, Reinier Post Stichting acknowledged the importance of providing expert support to resource-limited contexts, in order to ensure equitable access to available expertise in providing good quality, safe and effective infectious disease care. Following the SPICE registry establishment, first missions will be dispatched in late 2024, and will be offered on a ongoing basis.</p> */}
                </div>

                {/* <div>
                    <h1>
                        Missions
                    </h1>
                    <p>Currently, missions to Europe, East Africa and Indonesia are planned. If you are a registered expert, please check any incoming requests.
                    If you are interested in becoming an expert, please read more and apply <a href={AppRoutes.ExpertApply}>here.</a></p>
                </div> */}
            </div>
        </>
    )
}

export default WhereWeWorkComponent;