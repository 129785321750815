import { Button, Typography } from "@mui/material";
import './landing-page-cta.css';
import { Link as RouterLink } from "react-router-dom";
import { AppRoutes } from "../../consts/routes/app-routes";
import { StyledEngineProvider } from '@mui/material/styles';
import { forwardRef } from "react";

const LandingPageCta = () => {
    const CustomLink = forwardRef((props, ref) => (
        <RouterLink ref={ref} {...props} target="_blank" />
    ));

    return (
        <StyledEngineProvider injectFirst>
            <div id='cta-container'>
                <div id="cta-texts-container">
                    <Typography id="cta-heading" className="cta-texts" variant="h6">
                        <b>
                            Empowering Low-Resource Communities: Customized Support for Sustainable Infectious Disease Management
                        </b>
                    </Typography>
                <div id='buttons-container'>
                    <Button component={RouterLink} to={AppRoutes.RequestSupportApply} className="cta-button" >
                        <b>Request support</b>
                    </Button>
                    <Button component={RouterLink} to={AppRoutes.ExpertApply} className="cta-button" >
                        <b>Become an Expert</b>
                    </Button>
                    <Button component={RouterLink} to={AppRoutes.TrainerApply} className="cta-button">
                        <b>Become a Trainer</b>
                    </Button>
                </div>
                    <Typography className="cta-texts">
                        The Specialized Programme for Infectious Disease Care Everywhere (SPICE) 
                        is a global registry of experts with diverse (medical) specialties. 
                        We aim to improve infectious disease care everywhere by sharing knowledge 
                        and skills through online and on-site mentoring. Our experts are highly
                        knowledgeable, skilled and motivated to share their expertise with peers worldwide.
                    </Typography>
                    <Typography className="cta-texts">
                        Applications for experts are open to anyone with expertise in infectious disease management,
                        such as clinical microbiology, antimicrobial stewardship, infection prevention or implementation science and behavior change.                    </Typography>
                    <Typography className="cta-texts">
                        Are you part of a public healthcare facility in need of support? We are also open to <a href={AppRoutes.RequestSupportApply}><strong>requests for expert support in relevant clinical areas.</strong></a></Typography>
                    <Typography className="cta-texts">
                        Lastly, if you have already taken part in a drive-AMS Masterclass and want to get involved as a trainer, <a href={AppRoutes.TrainerApply}><strong>you can apply here.</strong></a>
                    </Typography>
                </div>
            </div>
        </StyledEngineProvider>
    )
}

export default LandingPageCta;