import { APIRoutes } from "../consts/routes/api-routes";
import API from "./base-service";

export const hospitalApply = async body => {
    try {
        const result = await API.post(APIRoutes.HospitalsApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (err) {
        return null;
    }
}

export const expertApply = async body => {
    try {
        const result = await API.post(APIRoutes.ExpertsApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (err) {
        return null;
    }
}

export const trainerApply = async body => {
    try {
        const result = await API.post(APIRoutes.TrainersApply, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (err) {
        return null;
    }
}

export const verifyCaptcha = async (body) => {
    try {
        const result = await API.post(APIRoutes.VerifyCaptcha, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
        return result.data;
    } catch (error) {
        return null;
    }
}

export const GetTweets = async () => {
    try {
        const result = await API.get(APIRoutes.Tweets);
        return result;
    } catch (error) {
        return null;
    }
}