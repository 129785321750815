import { AppRoutes } from "../routes/app-routes";

export const dropdownSubItems = {
    'Partnerships': [], 
    'Become an Expert': [
        {
            value: 'What is a SPICE expert?',
            route: '/become-expert/what-is-spice-expert'
        },
        // {
        //     value: 'Terms and conditions',
        //     route: '/become-expert/terms-and-conditions'
        // },
        {
            value: 'Meet our experts',
            route: '/become-expert/meet-our-experts',
        },
        {
            value: 'Apply',
            route: '/become-expert/apply',//'https://www.aanmelder.nl/137902/subscribe'
        }
    ],
    'Become a Trainer': [
        {
            value: 'What is AMS trainer?',
            route: '/become-trainer/what-is-ams-trainer'
        },
        // {
        //     value: 'Terms and conditions',
        //     route: '/become-trainer/terms-and-conditions'
        // },
        {
            value: 'Our trainers',
            route: '/#/expert-stories'
        },
        {
            value: 'Apply',
            route: '/become-trainer/apply'
        }
    ],

    'Request support': [
        // {
        //     value: 'Areas of support',
        //     route: '/request-support/areas-of-support'
        // },
        {
            value: 'Types of support',
            route: '/request-support/types-of-support'
        },
        // {
            // value: 'Terms and conditions',
            // route: '/request-support/terms-and-conditions',// RETURN WHEN CONTENT IS PROVIDED  '/request-support/terms-and-conditions'
        // },
        {
            value: 'How can SPICE help my facility?',
            route: '/request-support/how-can-spice-help-my-facility'
        },
        {
            value: 'Apply',
            route: '/request-support/apply'
        }
    ],

    'About': [
        {
            value: 'About SPICE',
            route: '/about/about-spice'
        },
        {
            value: 'Where we work',
            route: AppRoutes.WhereWeWork
        },
        // {
        //     value: 'Get involved',
        //     route: '/about/get-involved'
        // },
        {
            value: 'Contact Us',
            route: '/about/contact-us'
        }
    ]
};