import { useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';
import './meet-our-experts.css';
import img1 from '../../media/1.png';
import img2 from '../../media/2.png';
import img3 from '../../media/3.png';
import img4 from '../../media/4.png';
import img5 from '../../media/5.png';
import img6 from '../../media/6.jpg';
import img7 from '../../media/7.png';
import img8 from '../../media/8.jpg';
import img9 from '../../media/9.jpg';
import img10 from '../../media/10.png';
import { useLocation } from "react-router-dom";

const MeetOurExperts = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const targetElement = document.getElementById(location.hash.replace('#', ''));
            const headerHeight = 112;
            window.scrollTo({ top: targetElement.offsetTop - headerHeight, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <StyledEngineProvider injectFirst>
            <div>
                <Typography id="experts-heading" variant="h4" color="text.secondary" my={1.5}>
                    Meet our Experts
                </Typography>
                <Box id="experts-container" >
                    <div className="experts-box" id="jeroen" >
                        <p className='team-member-name'>Jeroen Schouten, MD, PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Jeroen Schouten" src={img1} />
                            <p>
                                Jeroen is an internist and intensivist and a senior researcher at the Scientific Center for Quality of Healthcare at Radboudumc.
                                He is experienced in qualitative and quantitative research methods and has been involved in many (inter)national initiatives to improve antibiotic prescribing (SWAB, ECCMID, ECDC, WHO).
                                Jeroen is a director of the ESCMID-AMS Master certificate program, past chair of ESGAP (ESCMID Study Group of Antimicrobial Stewardship) and a member of APUA Executive Committee.
                            </p>
                        </div>
                    </div>
                    <div className="experts-box" id="heiman">
                        <p className='team-member-name'>Heiman Wertheim, MD, PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Heiman Wertheim" src={img2} />
                            <p>Clinical microbiologist and head of Medical microbiology
                                department at Radboudumc. He is a president of the
                                Netherlands Society for Medical Microbiology (NVMM) and
                                board member of the International Society of Antimicrobial
                                Chemotherapy. Heiman is currently part of the WHO
                                Strategic and Technical Advisory group on AMR (STAG-
                                AMR). Furthermore he is on the program committee for
                                ESCMID Global. He has various research programs on AMR
                                in low resource settings.</p>
                        </div>
                    </div>
                    <div className="experts-box" id="jose" >
                        <p className='team-member-name'>José Artur Paiva, MD PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="José Artur Paiva" src={img3} />
                            <p>Specialist in Intensive Care Medicine and Internal Medicine.
                                Certified in Emergency Medicine and Management of Health
                                Systems. Currently, Director of Intensive Care Medicine
                                Service at Centro Hospitalar Universitário São João, Invited
                                Associate Professor of Medicine at Faculty of Medicine,
                                University of Porto and Director of the Infection and
                                Antimicrobial Resistance Prevention Program at the
                                Directorate General of Health – Portugal.</p>
                        </div>
                    </div>
                    <div className="experts-box" id="emmanuel" >
                        <p className='team-member-name'>Emmanuel Roilides, MD, PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Emmanuel Roilides" src={img4} />
                            <p>Professor of Paediatrics-Infectious Diseases in Aristotle
                                University School of Medicine at Hippokration Hospital in
                                Thessaloniki, Greece, currently directing the research
                                laboratory and the Division of Infectious Diseases and chairs
                                the 3rd Department of Pediatrics. He is Board member of
                                Special Unit for Biomedical Research and Education of the
                                School of Medicine responsible for Basic and Translational
                                Research. His research focuses on fungal infections and
                                multi-resistant Gram-negative bacteria.</p>
                        </div>
                    </div>
                    <div className="experts-box" id="marlies" >
                        <p className='team-member-name'>Marlies Hulscher, PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Marlies Hulscher" src={img10} />
                            <p>Professor of quality of care in infectious diseases and senior researcher at the Scientific Center for Quality of Healthcare  (IQ Healthcare) at the Radboudumc.
                                Marlies has more than 25 years’ experience in implementation research and behavioural science.
                                The central aim of her research is to define, measure and improve quality of care for patients with infectious diseases.
                            </p>
                        </div>
                    </div>
                    <div className="experts-box" id="jaap" >
                        <p className='team-member-name'>Jaap ten Oever, MD PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Jaap ten Oever" src={img9} />
                            <p>Internist-infectious disease specialist at Radboudumc involved in the development of the A-team in this hospital since the start.
                                Member of the Dutch National Working Party for Antimicrobial Policies (SWAB council) and chair of the SWAB committee for Antimicrobial stewardship.
                            </p>
                        </div>
                    </div>
                    <div className="experts-box" id="rolanda" >
                        <p className='team-member-name'>Rolanda Valinteliene, MD, PhD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Rolanda Valinteliene" src={img5} />
                            <p>Head of Public Health Technology Centre of Institute of
                                Hygiene and lector at Infectious Disease and
                                Dermatovenorology Clinic of Medical Faculty of Vilnius
                                University . She is epidemiologist and infection control expert.
                                Rolanda is Deputy chair of National AMR expert group,
                                national contact point in ECDC (HAI-NET, ESAC-NET), and
                                part of development and implementation of national AMR
                                action plan.</p>
                        </div>
                    </div>
                    <div className="experts-box" id="francisco" >
                        <p className='team-member-name'>Francisco Almeida, MD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Francisco Almeida" src={img6} />
                            <p>Infectious Diseases specialist working full-time in the
                                Antimicrobial Stewardship (AMS) and Infection Prevention
                                and Control (IPC) department in Centro Hospitalar de São
                                João (CHSJ) - Porto, Portugal. Coordinator of Antimicrobial
                                Stewardship collaborations in the Orthopedic surgery,
                                Neurosurgery and Hepatobiliary surgery departments, and
                                invited assistant professor of Clinical Epidemiology at Faculty
                                of Medicine of the University of Porto (FMUP).</p>
                        </div>
                    </div>
                    <div className="experts-box" id="raquel" >
                        <p className='team-member-name'>Raquel Duro, MD</p>
                        <div className='team-member-bio'>
                            <Avatar className="avatar-img" alt="Raquel Duro" src={img7} />
                            <p>Medical Degree at Faculdade de Medicina da Universidade do Porto. Residency in Infectious Diseases concluded in 2017 at Centro Hospitalar e Universitário de São João, Porto, Portugal.
                                Consultant in Infectious Diseases, primarily in Infection Control and Prevention and Antimicrobial Stewardship at several hospitals in Portugal.</p>
                        </div>
                    </div>
                </Box>

            </div>
        </StyledEngineProvider>
    )
}

export default MeetOurExperts;