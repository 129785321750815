import LandingPageCta from "../components/cta/landing-page-cta"
import FeaturedStory from "../components/info/featured-story"
import MeetOurExperts from "../components/info/meet-our-experts";
import NewsCarousel from "../components/info/featured-story-carousel"
import Divider from '@mui/material/Divider';
import { StyledEngineProvider } from '@mui/material/styles';
import './landing-page.css';
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import MeetOurExpertsLanindPage from "../components/info/meet-our-experts-lp";
import { XEmbed } from 'react-social-media-embed';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { GetTweets } from "../services/apply-service";

const LandingPage = () => {
    const expertsRef = useRef(null);
    const location = useLocation();
    const [tweets, setTweets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const twitterContainerRef = useRef(null);

    // useEffect(() => {
    //     const fetchTweets = async () => {
    //         try {
    //             // const response = await axios.get(
    //             //     // `http://localhost:5000/api/tweets?username=${username}`
    //             //     `http://localhost:5000/api/auth/tweets`
    //             // );
    //             // console.log(response)
    //             // setTweets(response.data.data || []);
    //             // setLoading(false);
    //             // GetTweets().then(res => console.log(res));
    //         } catch (err) {
    //             setError('Failed to fetch tweets');
    //             setLoading(false);
    //         }
    //     };

    //     // fetchTweets();
    // }, []);

    useEffect(() => {
        if (location.hash === '#/meet-our-experts' && expertsRef.current) {
            expertsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);
    
    return (
        <StyledEngineProvider injectFirst>
            <div className="container">
                <div>
                    <LandingPageCta />
                </div>
                <Divider variant="middle" sx={{ backgroundColor: 'gainsboro' }} />
                <div className="news-container">
                    <div className="news-inner-container">
                        {/* <NewsCarousel /> */}
                        <FeaturedStory />
                    </div>
                    {/* <div>
                        <XEmbed url="https://x.com/radboudumc/status/1859949119445373009" width={325} />
                    </div> */}
                </div>
                <Divider variant="middle" sx={{ backgroundColor: 'gainsboro' }} />
                <br></br>
                <div ref={expertsRef}>
                    <MeetOurExpertsLanindPage />
                </div>
            </div>
        </StyledEngineProvider>
    )
}

export default LandingPage;