import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';
import './meet-our-experts-lp.css';
import img1 from '../../media/1.png';
import img2 from '../../media/2.png';
import img3 from '../../media/3.png';
import img4 from '../../media/4.png';
import img5 from '../../media/5.png';
import img6 from '../../media/6.jpg';
import img7 from '../../media/7.png';
import img9 from '../../media/9.jpg';
import img10 from '../../media/10.png';
import { AppRoutes } from '../../consts/routes/app-routes';
import { Link } from 'react-router-dom';

const MeetOurExpertsLanindgPage = () => {
    return (
        <StyledEngineProvider injectFirst>
            <div>
                <Typography id="experts-heading-lp" variant="h4" color="text.secondary" my={1.5}>
                    Meet our Experts
                </Typography>
                <Box id="experts-container-lp" >
                    <Box className="experts-box-lp" id="jeroen">
                        <Avatar className="avatar-img" alt="Jeroen Schouten" src={img1} />
                        <p className='team-member-name-lp'>Jeroen Schouten, MD, PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Jeroen is an internist and intensivist and a senior researcher at the Scientific Center for Quality of Healthcare at Radboudumc.
                            </p>
                            <Link to={AppRoutes.ExpertStories + '/#jeroen'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="heiman">
                        <Avatar className="avatar-img" alt="Heiman Wertheim" src={img2} />
                        <p className='team-member-name-lp'>Heiman Wertheim, MD, PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Clinical microbiologist and head of Medical microbiology department at Radboudumc.</p>
                            <Link to={AppRoutes.ExpertStories + '/#heiman'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="jose">
                        <Avatar className="avatar-img" alt="José Artur Paiva" src={img3} />
                        <p className='team-member-name-lp'>José Artur Paiva, MD PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Specialist in Intensive Care Medicine and Internal Medicine.
                                Certified in Emergency Medicine and Management of Health
                                Systems.</p>
                            <Link to={AppRoutes.ExpertStories + '/#jose'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="emmanuel">
                        <Avatar className="avatar-img" alt="Emmanuel Roilides" src={img4} />
                        <p className='team-member-name-lp'>Emmanuel Roilides, MD, PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Professor of Paediatrics-Infectious Diseases in Aristotle
                                University School of Medicine at Hippokration Hospital in
                                Thessaloniki, Greece.</p>
                            <Link to={AppRoutes.ExpertStories + '/#emmanuel'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="marlies">
                        <Avatar className="avatar-img" alt="Marlies Hulscher" src={img10} />
                        <p className='team-member-name-lp'>Marlies Hulscher, PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Professor of quality of care in infectious diseases and senior researcher at the Scientific Center for Quality of Healthcare  (IQ Healthcare) at the Radboudumc.
                            </p>
                            <Link to={AppRoutes.ExpertStories + '/#marlies'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="jaap">
                        <Avatar className="avatar-img" alt="Jaap ten Oever" src={img9} />
                        <p className='team-member-name-lp'>Jaap ten Oever, MD PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Internist-infectious disease specialist at Radboudumc involved in the development of the A-team in this hospital since the start.
                            </p>
                            <Link to={AppRoutes.ExpertStories + '/#jaap'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="rolanda">
                        <Avatar className="avatar-img" alt="Rolanda Valinteliene" src={img5} />
                        <p className='team-member-name-lp'>Rolanda Valinteliene, MD, PhD</p>
                        <div className='team-member-bio-lp'>
                            <p>Head of Public Health Technology Centre of Institute of
                                Hygiene and lector at Infectious Disease and
                                Dermatovenorology Clinic of Medical Faculty of Vilnius
                                University.</p>
                            <Link to={AppRoutes.ExpertStories + '/#rolanda'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="francisco">
                        <Avatar className="avatar-img" alt="Francisco Almeida" src={img6} />
                        <p className='team-member-name-lp'>Francisco Almeida, MD</p>
                        <div className='team-member-bio-lp'>
                            <p>Infectious Diseases specialist working full-time in the
                                Antimicrobial Stewardship (AMS) and Infection Prevention
                                and Control (IPC) department in Centro Hospitalar de São
                                João (CHSJ) - Porto, Portugal.</p>
                            <Link to={AppRoutes.ExpertStories + '/#francisco'}>Read more</Link>
                        </div>
                    </Box>
                    <Box className="experts-box-lp" id="raquel">
                        <Avatar className="avatar-img" alt="Raquel Duro" src={img7} />
                        <p className='team-member-name-lp'>Raquel Duro, MD</p>
                        <div className='team-member-bio-lp'>
                            <p>Medical Degree at Faculdade de Medicina da Universidade do Porto.</p>
                            <Link to={AppRoutes.ExpertStories + '/#raquel'}>Read more</Link>
                        </div>
                    </Box>
                </Box>
            </div>
        </StyledEngineProvider>
    )
}

export default MeetOurExpertsLanindgPage;