import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, CardHeader } from '@mui/material';
import news1 from '../../media/rsz1.png';
import news2 from '../../media/rsz2.png';
import news3 from '../../media/rsz3.jpg';

const FeaturedStory = () => {
    const images = [
        // {
        //     label: 'Successful prophylaxis in orthopedic surgery intervention in Armenia',
        //     imgPath: news1,
        //     description: 'A senior clinical microbiologist visit to Armenian hospital leads to an important outcome of reducing antibiotic prophylaxis for orthopaedic surgery from 5 days to a single day. He presented the evidence to a large group of orthopedic surgeons who afterwards changed their practice.'
        // },
        // {
        //     label: 'AMS Masterclass helps setup hospital Antimicrobial Stewardship program',
        //     imgPath: news2,
        //     description: 'Dr Christian Lanckohr and his team from the University Hospital Muenster, Germany have managed to establish a sustainable AMS program in their hospital following the AMS Masterclass & expert consultancy in 2019. With the attention on AMS being critically important part of patient care and a matter of patient safety, the hospital established a functional AMS-team with hospital-wide authority and dedicated hours. With the expert consultancy support, the team established regular AMS-rounds into Orthopedics, Oncology and Cardiac Surgery departments, yeilding results in reduced surgical prophylaxis. This showcase of the importance of consultative multi-disciplinary approach in dealing with appropriate antibiotic use serves as an example for other hospitals in the region, including ambulatory settings, through their „AMS Network“.'
        // },
        {
            label: 'PAHO project to analyze the infection care system in Surinam',
            imgPath: news3,
            description: ' Prof Heiman Wertheim lead a PAHO mission in Surinam to analyze the infection care system. Suriam is a relatively small country and therefore it is a huge challenge to maintain enough people trained for infection control, clinical microbiology and antimicrobial treatment. An important recommendation was to organize this community in a society (VMS) and initiate a national infection committee and antibiotic policy committee. Considering the challenge of getting reagents for microbiology testing in Surinam It was recommended to install a malditof system in the AZP microbiology lab. This is currently completed. Furthermore a second person is now in training to become a  clinical microbiologist.'
        }
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '95%',
                // height: '80vh',
                margin: '0 auto',
                padding: '20px'
            }}
        >
            <Typography variant="h4" color="text.secondary" textAlign="center" my={1.5}>
                Local Impact
            </Typography>
            {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Spreads the cards apart
                    gap: 2, // Adds spacing between the cards
                    flexWrap: 'wrap', // Ensures responsiveness for smaller screens
                    width: '100%',
                }}
            >
                {images.map(el => (
                    <Card sx={{ flex: 1, minWidth: '45%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'space-between' }}>
                        <CardContent style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: '5px', alignContent: 'space-between' }}>
                            <div style={{ marginBottom: '1rem', height: '5rem' }} >
                                <Typography variant="h6" component="div" textAlign="center">
                                    {el.label}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', gap: '2rem' }}>
                                <Typography variant="body2" color="text.secondary" textAlign="justify">
                                    {el.description}
                                </Typography>
                                <CardMedia>

                                    <img width={210} height={'100%'} src={el.imgPath} />
                                </CardMedia>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </Box> */}
            {images.map((el) => (
                    <div key={el.label}>
                            <Card >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="450"
                                        image={el.imgPath}
                                        alt={el.label}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                                            {el.label}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" textAlign={'justify'}>
                                            {el.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                    </div>))}
        </Box>
    );
}

export default FeaturedStory;