export const requiredHospitalsApplyFormProps = [
    'name',
    'address',
    'city',
    'country',
    'legal_representative_authorized_person',
    'legal_representative_authorized_person_position',
    'contact_person',
    'contact_person_position',
    'phone_number',
    'email',
    'about_the_hospital',
    'institution_level_id',
    'institution_type_id',
    'field_of_expertise_ids',
    'requested_support_description',
    // 'letter_of_commitment_base64',
    // 'comments_and_additional_info',
    // 'consent',
    'terms_and_conditions',
    'information_accuracy_confirmation'
];

export const requiredExpertsApplyFormProps = [
    'name',
    'surname',
    'institution',
    'phone',
    'email',
    'ORCID',
    'researcher_id',
    'national_registry_id',
    'country',
    'level_of_expertise_id',
    // 'level_of_expertise_other',
    'work_experience',
    'professional_resume_base64',
    'professional_endorsment_base64',
    'specialty_ids',
    'professional_society_membership',
    // 'specialty_other',
    // 'specialty_surgery_subspecialty',
    'registry_related_experience',
    // 'registry_related_experience_other',
    'regional_experience',
    // 'regional_experience_countries',
    'language_proficiency',
    // 'language_proficiency_other',
    'general_availability',
    // 'general_availability_time',
    // 'general_availability_other',
    'consent',
    'declaration_of_honour'
];

export const requiredTrainersApplyFormProps = [
    'name',
    'surname',
    'email',
    'phone_number',
    'drive_ams_course_attended',
    'country',
    'field_of_expertise_ids' 
];

export const hospitalsApplyFormProps = {
    name: '',
    address: '',
    city: '',
    country: '',
    legal_representative_authorized_person: '',
    legal_representative_authorized_person_position: '',
    contact_person: '',
    contact_person_position: '',
    phone_number: '',
    email: '',
    about_the_hospital: '',
    institution_level_id: null,
    institution_level_other: null,
    institution_type_id: null,
    institution_type_other: null,
    field_of_expertise_ids: null,
    field_of_expertise_other: null,
    requested_support_description: '',
    // letter_of_commitment_base64: null,
    // letter_of_commitment_base64_extension: null,
    comments_and_additional_info: '',
    // consent: false,
    terms_and_conditions: false,
    information_accuracy_confirmation: false
};

export const expertsApplyFormProps = {
    name: '',
    surname: '',
    institution: '',
    phone: '',
    email: '',
    ORCID: '',
    researcher_id: '',
    national_registry_id: '',
    country: '',
    level_of_expertise_id: null,
    level_of_expertise_other: '',
    work_experience: null,
    professional_resume_base64: null,
    professional_resume_base64_extension: null,
    professional_endorsment_base64: null,
    professional_endorsment_base64_extension: null,
    professional_society_membership: '',
    specialty_ids: [],
    specialty_other: '',
    specialty_surgery_subspecialty: '',
    registry_related_experience: [],
    registry_related_experience_other: '',
    regional_experience: [],
    regional_experience_countries: '',
    language_proficiency: [],
    language_proficiency_other: '',
    general_availability: '',
    general_availability_time: '',
    general_availability_other: '',
    consent: false,
    declaration_of_honour: false
};

export const trainersApplyFormProps = {
    name: '',
    surname: '',
    email: '',
    phone_number: '',
    drive_ams_course_attended: '',
    country: '',
    field_of_expertise_ids: null  
};