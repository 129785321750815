import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { pages } from "../../consts/nav-items/pages";
import { dropdownSubItems } from "../../consts/nav-items/sub-items";
import { Link as RouterLink } from "react-router-dom";
import './navbar.css';
import logoNoTextWhite from '../../media/SpiceLogo-NoTextWhite.png';

const NavBar = () => {
    const CustomLink = React.forwardRef((props, ref) => <RouterLink ref={ref} {...props} target="_blank" />);

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentMenu, setCurrentMenu] = useState(null);
    const handleOpenNavMenu = e => setAnchorElNav(e.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);

    const handleOpenSubMenu = (e, page) => {
        setAnchorEl(e.currentTarget);
        setCurrentMenu(page);
    };

    const handleCloseSubMenu = () => {
        handleCloseNavMenu();
        setAnchorEl(null);
        setCurrentMenu(null);
    };

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#1989b0', height: '7rem' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={logoNoTextWhite} alt="Logo" onClick={() => window.location.replace('/')} style={{ display: { xs: 'none', md: 'flex' }, marginRight: '1rem', height: '12.5vh', marginBottom: '1.5rem', cursor: 'pointer' }} />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-label="menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" component={RouterLink} className="reset-link">
                            <MenuIcon />
                        </IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ display: { xs: 'block', md: 'none' } }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} keepMounted>
                            {pages.map((page) => (
                                <div key={page.value}>
                                    {page.route !== '' ? (
                                        <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to={page.route}>
                                            <Typography textAlign="center">{page.value}</Typography>
                                        </MenuItem>
                                    ) : (
                                        <>
                                            <MenuItem
                                                component={page.route.substring(0, 5) === 'https' ? CustomLink : RouterLink} className="reset-link"
                                                onClick={(e) => dropdownSubItems[page.value]?.length === 0 ? null : handleOpenSubMenu(e, page.value)}
                                            >
                                                <Typography textAlign="center">{page.value}</Typography>
                                            </MenuItem>
                                            {dropdownSubItems[page.value]?.length > 0 && (
                                                <Menu anchorEl={anchorEl} open={currentMenu === page.value} onClose={handleCloseSubMenu} MenuListProps={{ onMouseLeave: handleCloseSubMenu }}>
                                                    {dropdownSubItems[page.value].map((item) => (
                                                        <MenuItem
                                                            component={item.route.substring(0, 5) === 'https' ? CustomLink : RouterLink}
                                                            to={item.route} className="reset-link" key={item.value}
                                                            sx={{ '&:hover': { backgroundColor: '#6c94a6', color: '#fff' } }} onClick={handleCloseSubMenu}
                                                        >
                                                            {item.value}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <div key={page.value}>
                                {page.route !== '' ?
                                    <>
                                        <Button
                                            component={page.route.substring(0, 5) === 'https' ? CustomLink : RouterLink} to={page.route} className="reset-link"
                                            onClick={(e) => dropdownSubItems[page.value]?.length === 0 ? null : handleOpenSubMenu(e, page.value)}
                                            sx={{ my: 2, mx: 1, color: 'white', display: 'block', textTransform: 'none', fontSize: '17px', '&:hover': { backgroundColor: '#fff', color: '#6c94a6' } }}
                                        >
                                            {page.value}
                                        </Button>
                                    </> : <>
                                        <Button
                                            onClick={(e) => dropdownSubItems[page]?.length === 0 ? console.log('nothing') : handleOpenSubMenu(e, page.value)}
                                            sx={{ my: 2, mx: 1, color: 'white', display: 'block', textTransform: 'none',  fontSize: '17px', '&:hover': { backgroundColor: '#fff', color: '#6c94a6' } }}
                                        >
                                            {page.value}
                                        </Button>
                                    </>
                                }
                                <Menu anchorEl={anchorEl} open={currentMenu === page.value} onClose={handleCloseSubMenu} MenuListProps={{ onMouseLeave: handleCloseSubMenu }}>
                                    {dropdownSubItems[page.value]?.map((item) => (
                                        <MenuItem component={item.route.substring(0, 5) === 'https' ? CustomLink : RouterLink} to={item.route}
                                            className="reset-link" sx={{ '&:hover': { backgroundColor: '#6c94a6', color: '#fff' } }} key={item.value} onClick={handleCloseSubMenu}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;