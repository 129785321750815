import "./about-spice.css";

const AboutSpiceComponent = () => {
    return (
        <>
            <div class="content-about">
                <h4>About SPICE</h4>
                <p class="intro">
                Governments worldwide have acknowledged an antimicrobial resistance (AMR) crisis, emphasizing the need for tailored policy solutions, improved infection prevention, antimicrobial stewardship (AMS) and better antibiotic use. There is a global need for sustainable solutions, especially in resource-limited settings where the burden of infectious disease is highest.                </p>
                <p class="intro">
                The Specialized Program for Infectious Disease Care Everywhere (SPICE) was initiated to address this challenge across diverse contexts. We aim to match local needs to available expertise to facilitate the provision of quality infectious disease care everywhere. We believe in sustainable change through local capacity building, reducing the dependency on foreign aid and short-term interventions.                  </p>
                <p class="intro">
                In particular, SPICE offers expert advice and implementation support in the following areas:                 </p>
                <ul>
                    <li>Infectious disease management</li>
                    <li>Antimicrobial Stewardship</li>
                    <li>Microbiology and diagnostics</li>
                    <li>Measurement of antimicrobial use</li>
                    <li>Infection prevention and control</li>
                    <li>Implementation science and behavior change</li>
                </ul>
                    
                {/* <h4>Governance</h4>
                <h4>Secretariat</h4>
                <h4>Projects</h4>
                <h4>Missions</h4> */}
                <h4>How we work</h4>
                <p class="intro">
                    The SPICE program entails a registry of experts with diverse medical specialties pertinent to infectious diseases, who offer their expertise and time to support healthcare facilities in achieving specific goals or address localized problems with infectious diseases management at their facility. Through matching specific expertise to the requested support for a particular problem, SPICE helps to create local positive impact, and in that way contribute to addressing the infectious diseases challenge on a global scale.
                </p>
            </div>
        </>
    )
}

export default AboutSpiceComponent;