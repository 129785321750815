import { AppRoutes } from '../../consts/routes/app-routes';
import './partnerships.css';

const PartnershipsComponent = () => {
    return (
        <div class="content-p">
            <p class="intro">
                The SPICE registry is supported by partnerships  with international organisations, government institutions, professional societies and other stakeholders. By working together, we encourage mutual exchange of knowledge and experience and strengthening of efforts in the field of AMS and IPC. We aim to assist healthcare workers, policy makers and governments in implementing specialized infectious disease care in a sustainable way. See below for an overview of our partners and how we collaborate:
            </p>
            <h1>Get Involved</h1>
            <p>
                A collaborative approach is crucial for sustainable solutions to address the pressing infectious disease burden globally and locally.
                We are committed to increasing our impact by working with national, regional and international partners. Partnering with us can take various forms:
            </p>
            <h4>Ground-level partnerships - joining forces in Implementation</h4>
            <p>
                Working closely with other organizations who share our mission ensures that our impact is strengthened.
                Are you active in the field of AMS and infectious disease management, and want to know more about how we can join hands?
                Please reach out to us <a href={AppRoutes.ContactUs}>here</a>.
            </p>
            <p>Our implementation partners include:</p>
            <ul class="partnerships">
                <li>
                    Drive-AMS East Africa
                    <ul class="partnerships-child">
                        <li>Kilimanjaro Christian Medical Center, Moshi, Tanzania</li>
                        <li>Muhimbili National Hospital, Dar es Salaam, Tanzania</li>
                        <li>Centre Hospitalier Universitaire de Butare, Butare, Rwanda</li>
                        <li>Centre Hospitalier Universitaire de Kigali, Kigali, Rwanda</li>
                    </ul>
                </li>
                <li>
                    Drive-AMS Europe
                    <ul class="partnerships-child">
                        <li>Radboud University Medical Center, Nijmegen, the Netherlands.</li>
                        <li>Faculty of Medicine and Health Science, University of Antwerp, Antwerp, Belgium</li>
                        <li>School of Medicine, Faculty of Health Sciences, Aristotle University of Thessaloniki, Thessaloniki, Greece</li>
                        <li>Iuliu Hațieganu, University of Medicine and Pharmacy, Cluj-Napoca, Romania</li>
                        <li>Centro Hospitalar Universitário de São João, Porto, Portugal</li>
                        <li>Higienos institutas, Vilnius, Lithuania</li>
                    </ul>
                </li>
            </ul>
            <h4>Funding partnerships - supporting our mission and activities</h4>
                <p>SPICE is dependent on financial support to continue its mission. Our funding partners and supporters include:</p>
                <ul class="partnerships">
                    <li>Stichting Reinier Post</li>
                    <li>International Centre for Antimicrobial Resistance Solutions (ICARS)</li>
                    <li>European Commission – HaDEA, EU4Health programme 2021-2027</li>
                </ul>
            <h4>Mission partnerships - exchanging and complementing expertise and capacities</h4>
            <p>Our partnerships strive for complementarity of actions and build-up of existing efforts and expertise, for optimal use of limited resources in addressing the growing need for high quality infectious disease care everywhere</p>
            <ul class="partnerhsips">
                <li><a href='https://www.pum.nl' target='_blank'>Dutch Programma Uitzending Managers</a></li>
                <li><a href="https://www.escmid.org/education/ams-certificate/" target='_blank'>ESCMID- AMS certificate programme</a></li>
            </ul>
            <p class="contact">
            Want to know more about how your organization can work with SPICE to achieve improved infectious disease care everywhere? Please get in touch with us <a href={AppRoutes.ContactUs}>here</a>.
        </p>
        </div>
    )
}

export default PartnershipsComponent;